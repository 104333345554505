import React, { useEffect } from 'react';
import { motion, useAnimationControls } from 'framer-motion';
import Marquee from 'react-fast-marquee';

import TextAnimation from '../macros/textAnimation';
import { Excellence } from '../macros/signatures';
import useWindowSize from '../../hooks/useWindowSize';
import { content } from '../../global/language';
import { LanguageContext } from '../../contexts/languageContext';

export default function HeroSection({ isLoaded }) {
	const windowSize = useWindowSize();
	const animationController = useAnimationControls();
	const { language } = React.useContext(LanguageContext);

	useEffect(() => {
		if (isLoaded) {
			setTimeout(() => {
				animationController.start('visible');
			}, 1000);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isLoaded]);

	return (
		<div className="relative flex flex-col items-center justify-center overflow-hidden my-0 mx-auto main-container h-full">
			<div className="flex flex-col justify-center items-center h-full relative -top-8 md:top-0">
				<motion.h3
					initial="hidden"
					animate={animationController}
					variants={{
						hidden: {
							opacity: 0,
						},
						visible: {
							opacity: 1,
							transition: {
								duration: 0.8,
								delay: 1,
								ease: [0.455, 0.03, 0.515, 0.955],
							},
						},
					}}
					className="font-sans font-light uppercase tracking-wider opacity-80 text-[1.2rem] xl:text-[1.5rem] "
				>
					{content.hero[language].subtitle}
				</motion.h3>
				<h1 className="w-full flex flex-col font-head font-bold items-center text-5xl md:text-[3.5rem] xl:text-[4.8rem] 2xl:text-[5.7rem]">
					{windowSize.width > 1280 && (
						<>
							<span className="flex items-center justify-center">
								<TextAnimation
									isLoaded={isLoaded}
									text={
										content.hero[language].title.part1 +
										' ' +
										content.hero[language].title.part2
									}
								/>
							</span>
							<span className="flex justify-center items-center gap-4">
								<div className="">
									<TextAnimation
										isLoaded={isLoaded}
										text={
											content.hero[language].title.part3
										}
									/>
								</div>
								<Excellence
									isLoaded={isLoaded}
									className="stroke-[5px] lg:w-[300px] xl:w-[390px] 2xl:w-[500px]"
								/>
							</span>
						</>
					)}
					{windowSize.width <= 1280 && (
						<>
							<span className="line flex items-center flex-col justify-center">
								<TextAnimation
									isLoaded={isLoaded}
									text={content.hero[language].title.part1}
								/>
								<TextAnimation
									isLoaded={isLoaded}
									text={content.hero[language].title.part2}
								/>
								<div className="flex flex-col items-center justify-center">
									<TextAnimation
										isLoaded={isLoaded}
										text={
											content.hero[language].title.part3
										}
									/>
								</div>
								<Excellence
									isLoaded={isLoaded}
									className="rotate-[-5deg] stroke-[4px] mb-2 mt-[-3rem]"
								/>
							</span>
						</>
					)}
				</h1>
			</div>
			<div className="absolute w-full sm:w-1/4 flex flex-col self-start left-0 bottom-24 md:bottom-16">
				<motion.span
					initial={{ opacity: 0, x: '-100%' }}
					animate={animationController}
					variants={{
						visible: {
							opacity: 1,
							x: 0,
							transition: {
								duration: 1,
								delay: 1,
								staggerChildren: 0.5,
							},
						},
					}}
					className="font-light font-head italic tracking-wide text-[1.5rem] xl:text-[2rem]"
				>
					{content.hero[language].marquee.title}
				</motion.span>
				<motion.span
					initial={{ opacity: 0, x: '-100%' }}
					animate={animationController}
					variants={{
						visible: {
							opacity: 1,
							x: 0,
							transition: {
								duration: 1,
								delay: 1,
								staggerChildren: 0.5,
							},
						},
					}}
					transition={{ duration: 1, delay: 1 }}
				>
					<Marquee
						gradient={false}
						speed={50}
						delay={4}
						direction="left"
						loop={0}
						pauseOnHover={true}
						className="overflow-hidden font-sans font-regular xl:text-2xl"
					>
						{content.hero[language].marquee.content}&nbsp;
					</Marquee>
				</motion.span>
			</div>
		</div>
	);
}
