import { AnimatePresence, motion } from 'framer-motion';
import { LanguageContext } from '../contexts/languageContext';
import { useContext } from 'react';

function Loader({ isLoaded, percentage }) {
	const { language } = useContext(LanguageContext);
	return (
		<AnimatePresence>
			{!isLoaded && (
				<motion.div
					className="w-screen h-screen fixed z-[1000] bg-primary"
					initial={{ height: '0' }}
					animate={{ height: '100vh' }}
					transition={{
						duration: 0.3,
					}}
					exit={{
						height: 0,
						transition: {
							duration: 1,
							ease: 'easeInOut',
							delay: 0.5,
						},
					}}
				>
					<motion.div
						className="fixed w-screen h-screen bg-[#101010] z-[100000]"
						initial={{ height: '0' }}
						animate={{ height: '100vh' }}
						transition={{
							duration: 0.3,
							delay: 0.1,
						}}
						exit={{
							height: 0,
							transition: {
								duration: 1,
								ease: 'easeInOut',
								delay: 0.3,
							},
						}}
					>
						<motion.span
							className="absolute text-2xl left-1/2 top-[45%] -translate-x-1/2 -translate-y-1/2 transform font-light opacity-80 tracking-wider text-center"
							initial={{ opacity: 0 }}
							animate={{ opacity: 1 }}
							transition={{
								duration: 0.3,
								delay: 1,
							}}
							exit={{
								opacity: 0,
								transition: {
									duration: 0.3,
									delay: 0.5,
								},
							}}
						>
							{language === 'en'
								? 'Good Things take time'
								: language === 'fr'
								? 'Les bonnes choses prennent du temps'
								: ''}
							<span className="text-primary font-bold">!</span>
						</motion.span>
						<motion.span
							className="absolute font-head text-[6rem] lg:text-[8rem] xl:text-[14rem] font-bold bottom-24 md:bottom-8 left-16 xl:left-32"
							initial={{ opacity: 0 }}
							animate={{ opacity: 1 }}
							transition={{
								duration: 0.3,
								delay: 0.5,
							}}
							exit={{
								opacity: 0,
								transition: {
									duration: 0.3,
									delay: 0.5,
								},
							}}
						>
							{percentage}%
						</motion.span>
					</motion.div>
				</motion.div>
			)}
		</AnimatePresence>
	);
}

export default Loader;
