import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { MouseContext } from '../../contexts/mouseContext';

function Button({ label, to, type, section, submit, download }) {
	const { cursorChangeHandler } = useContext(MouseContext);

	const navigate = useNavigate();

	const handleLinkClick = () => {
		return navigate(to, { state: { id: section } });
	};

	const buttonCore = (
		<>
			<span className="btn__circle"></span>
			<span className="btn__white-circle w-[30px] h-[30px] xl:w-[58px] xl:h-[58px]">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21 12">
					<path
						className="w-[16px]"
						d="M17.104 5.072l-4.138-4.014L14.056 0l6 5.82-6 5.82-1.09-1.057 4.138-4.014H0V5.072h17.104z"
					></path>
				</svg>
			</span>
			<span className="btn__text capitalize text-lg xl:text-2xl">
				{label}
			</span>
		</>
	);

	if (download) {
		return (
			<a
				href={to}
				download={download}
				className="btn p-[22px] xl:p-[30px]"
				onMouseEnter={() => cursorChangeHandler('large')}
				onMouseLeave={() => cursorChangeHandler('')}
			>
				{buttonCore}
			</a>
		);
	}

	if (submit) {
		return (
			<button
				type="submit"
				onClick={submit}
				className="btn p-[22px] xl:p-[30px]"
				onMouseEnter={() => cursorChangeHandler('large')}
				onMouseLeave={() => cursorChangeHandler('')}
			>
				{buttonCore}
			</button>
		);
	}

	return (
		<>
			{type === 'internal' ? (
				<div
					className="btn p-[22px] xl:p-[30px]"
					onClick={handleLinkClick}
					onMouseEnter={() => cursorChangeHandler('large')}
					onMouseLeave={() => cursorChangeHandler('')}
				>
					{buttonCore}
				</div>
			) : (
				<a
					href={to}
					target="_blank"
					rel="noreferrer"
					className="btn p-[22px] xl:p-[30px]"
					onMouseEnter={() => cursorChangeHandler('large')}
					onMouseLeave={() => cursorChangeHandler('')}
				>
					{buttonCore}
				</a>
			)}
		</>
	);
}

export default Button;
