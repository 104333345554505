import { AnimatePresence } from 'framer-motion';
import { lazy } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';

import Home from '../pages/home';
import Gallery from '../pages/gallery';
import NotFound from '../pages/404';

const Project = lazy(() => import('../pages/project'));

export default function AnimatedRoute() {
	const location = useLocation();

	return (
		<AnimatePresence mode="wait">
			<Routes location={location} key={location.pathname}>
				<Route path="/" element={<Home />} />
				<Route path="/project/*" element={<Project />} />
				<Route path="/gallery" element={<Gallery />} />
				<Route path="*" element={<NotFound />} />
			</Routes>
		</AnimatePresence>
	);
}
