import React, { useContext, useEffect } from 'react';
import { motion } from 'framer-motion';

import Navbar from '../components/navbar';
import Button from '../components/macros/button';
import { LanguageContext } from '../contexts/languageContext';

function NotFound() {
	useEffect(() => {
		window.document.title = `Achraf El fadili | 404`;
	}, []);

	const { language } = useContext(LanguageContext);

	return (
		<motion.main
			initial={{ opacity: 0 }}
			animate={{ opacity: 1 }}
			transition={{ duration: 1 }}
			exit={{ opacity: 0, y: '-100%' }}
			className="relative w-screen h-screen text-white flex items-center justify-center"
		>
			<Navbar />
			<div className="flex flex-col gap-16 items-center">
				<div className="flex flex-col items-center">
					<span className="text-[8rem] lg:text-[14rem] tracking-wider font-head font-bold">
						404
					</span>
					<span className="text-xl lg:text-2xl tracking-wide font-light opacity-60 -mt-8">
						{language === 'en'
							? 'Page Not Found'
							: 'Page non trouvée'}
					</span>
				</div>
				<Button label="Go Back" to="/" type="internal" />
			</div>
		</motion.main>
	);
}

export default NotFound;
