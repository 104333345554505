export const content = {
    navbar: {
        en: {
            about: 'About',
            projects: 'Projects',
            contact: 'Contact',
            gallery: 'Gallery',
        },
        fr: {
            about: 'À propos',
            projects: 'Projets',
            contact: 'Contact',
            gallery: 'Galerie',
        },
    },
    hero: {
        en: {
            subtitle: 'Committed to',
            title: {
                part1: 'Designing,',
                part2: 'Developing',
                part3: 'And Delivering',
            },
            marquee: {
                title: 'Open for work',
                content: 'Web Development • UI/UX Design • Devops • Automation •',
            }
        },
        fr: {
            subtitle: 'Engagé à',
            title: {
                part1: 'Concevoir,',
                part2: 'Développer',
                part3: 'Et Livrer',
            },
            marquee: {
                title: 'Ouvert pour travailler',
                content: 'Développement Web • Conception UI/UX • Devops • Automatisation •',
            }
        },
    },
    about: {
        en: {
            title: 'About',
            heading: 'Hey, I\'m Achraf El Fadili',
            paragraph: {
                part1: 'And i am a full-stack developer and designer raised in sunny',
                part2: ', Morocco. Currently, I am a student at',
                part3: 'a 42-like school located in',
                part4: 'Where I am honing my skills in various fields of computer Science and design principles.',
            },
            cta: 'Resume'
        },
        fr: {
            title: 'À propos',
            heading: 'Salut, je suis Achraf El Fadili',
            paragraph: {
                part1: 'Et je suis un développeur full-stack et designer, élevé dans le ensoleillé',
                part2: ', Maroc. Actuellement, je suis étudiant à l\'école',
                part3: 'une école 42-like située à',
                part4: 'Où je perfectionne mes compétences dans divers domaines de l\'informatique et des principes de design.',
            },
            cta: 'Télécharger le CV'
        },
    },
    projects: {
        en: {
            title: 'Projects',
            heading: 'I\'ve worked with some amazing clients!',
        },
        fr: {
            title: 'Projets',
            heading: 'J\'ai travaillé avec des clients incroyables!',
        },
    },
    contact: {
        en: {
            title: 'Contact',
            heading: 'Stay in touch!',
            form: {
                name: 'Full Name',
                email: 'Email',
                subject: 'Subject',
                message: 'Message',
                button: 'Submit',
            }
        },
        fr: {
            title: 'Contact',
            heading: 'Restez en contact!',
            form: {
                name: 'Nom complet',
                email: 'Email',
                subject: 'Sujet',
                message: 'Message',
                button: 'Soumettre',
            }
        },
    },
}