import { createContext, useState } from 'react';

export const LanguageContext = createContext({
	language: 'en',
	changeLanguage: (language) => {},
});

const LanguageContextProvider = (props) => {
	const [language, setLanguage] = useState(
		localStorage.getItem('language') || 'en',
	);

	const changeLanguage = (language) => {
		localStorage.setItem('language', language);
		setLanguage(language);
		window.location.reload();
		// navigate(window.location.hash === '#/' ? '/' : '/');
	};

	return (
		<LanguageContext.Provider
			value={{
				language: language,
				changeLanguage: changeLanguage,
			}}
		>
			{props.children}
		</LanguageContext.Provider>
	);
};

export default LanguageContextProvider;
