import React from 'react';

export default function Section({ children, id }) {
	return (
		<section
			id={id}
			className="w-full h-screen snap-start snap-always flex justify-center items-center relative z-[200]"
		>
			{children}
		</section>
	);
}
