import React, { useEffect, useRef, useState } from 'react';
import { motion } from 'framer-motion';
import { useLocation } from 'react-router-dom';

import Navbar from '../components/navbar';

import Section from '../components/section';
import HeroSection from '../components/sections/hero';
import AboutSection from '../components/sections/about';
import ContactSection from '../components/sections/contact';
import ProjectsSection from '../components/sections/projects';
import Loader from '../components/loader';

export default function Home() {
	const [percentage, setPercentage] = useState(0);
	const [isLoaded, setIsLoaded] = useState(false);
	const [isRunning, setIsRunning] = useState(false);
	const location = useLocation();
	const total = useRef(-1);
	const loaded = useRef(0);

	const onLoadEvent = () => {
		loaded.current++;
		if (loaded.current >= total.current) {
			setIsRunning(true);
		}
	};

	useEffect(() => {
		window.document.title = `Portfolio | Achraf El fadili`;
		const parent = document.querySelector('#main');
		const children = parent.querySelectorAll('img');

		if (total.current === -1) {
			total.current = children.length;
		}

		for (let i = 0; i < children.length; i++) {
			children[i].addEventListener('load', onLoadEvent());
		}
	}, []);

	useEffect(() => {
		if (percentage < 100 && isRunning) {
			setTimeout(() => {
				setPercentage((prevState) => prevState + 4);
			}, 50);
		} else if (percentage >= 100) {
			setPercentage(100);
			setIsLoaded(true);
		}
	}, [percentage, isRunning]);

	useEffect(() => {
		if (location.state && location.state.id) {
			const element = document.getElementById(location.state.id);
			element.scrollIntoView({
				block: 'start',
			});
			window.location.hash = '';
		}
		// console.log(location);
		// console.log(window.location);
	}, [location]);

	return (
		<>
			<Navbar />
			<Loader percentage={percentage} isLoaded={isLoaded} />
			<motion.div
				initial={{ opacity: 0 }}
				animate={{ opacity: 1 }}
				transition={{ duration: 1 }}
				exit={{ opacity: 0 }}
			>
				<main
					id="main"
					className="w-full scroll-bar-hide h-screen snap-mandatory snap-y overflow-y-scroll"
				>
					<Section id="hero">
						<HeroSection isLoaded={isLoaded} />
					</Section>
					<Section id="about">
						<AboutSection />
					</Section>
					<Section id="projects">
						<ProjectsSection />
					</Section>
					<Section id="contact">
						<ContactSection />
					</Section>
				</main>
			</motion.div>
		</>
	);
}
