import { AnimatePresence, motion } from 'framer-motion';
import { useState, useRef, useContext, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { LanguageContext } from '../contexts/languageContext';
import { MouseContext } from '../contexts/mouseContext';

import logo from '../assets/svgs/logo.svg';
import discord from '../assets/svgs/discord.svg';
import github from '../assets/svgs/github.svg';
import dribbble from '../assets/svgs/dribbble.svg';
import linkedin from '../assets/svgs/linkedin.svg';
import { content } from '../global/language';

const links = [
	{ key: 'about', id: 'about', type: 'internal' },
	{ key: 'projects', id: 'projects', type: 'internal' },
	{ key: 'contact', id: 'contact', type: 'internal' },
	{ key: 'gallery', id: '/gallery', type: 'external' },
];

const socialMedia = [
	{
		href: 'https://github.com/0xShady',
		label: 'Github',
		icon: <img src={github} alt="github" />,
	},
	{
		href: 'https://www.linkedin.com/in/achraf-el-fadili/',
		label: 'Linkedin',
		icon: <img src={linkedin} alt="linkedin" />,
	},
	{
		href: 'https://discordapp.com/users/603373384490942482',
		label: 'Discord',
		icon: <img src={discord} alt="discord" />,
	},
	{
		href: 'https://dribbble.com/achraf_elfadili',
		label: 'Dribble',
		icon: <img src={dribbble} alt="dribbble" />,
	},
];

export default function Navbar() {
	const [isOpen, setIsOpen] = useState(false);
	const menuRef = useRef(null);
	const { cursorChangeHandler } = useContext(MouseContext);
	const { language, changeLanguage } = useContext(LanguageContext);

	const toggleMenu = () => {
		if (isOpen) {
			menuRef.current.classList.remove('open');
		} else {
			menuRef.current.classList.add('open');
		}
		setIsOpen(!isOpen);
	};

	const handleEscape = (e) => {
		if (e.key === 'Escape') {
			menuRef.current.classList.remove('open');
			setIsOpen(false);
		}
	};

	const navigate = useNavigate();

	const handleLinkClick = (id, type) => {
		cursorChangeHandler('');
		if (type === 'external') {
			return navigate(id);
		}
		if (window.location.hash === '#/') {
			toggleMenu();
			const element = document.getElementById(id);
			element.scrollIntoView();
		}
		toggleMenu();
		navigate('/', { state: { id: id } });
	};

	useEffect(() => {
		document.addEventListener('keydown', handleEscape);
		return () => {
			document.removeEventListener('keydown', handleEscape);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<motion.div
				className="fixed items-center top-4 md:top-8 xl:top-12 left-0 right-0 m-auto flex justify-between main-container z-[600] mix-blend-difference"
				initial={{ opacity: 0, y: -20 }}
				animate={{ opacity: 1, y: 0 }}
				transition={{ duration: 1 }}
			>
				<Link to="/" reloadDocument>
					<img
						onMouseEnter={() => cursorChangeHandler('large')}
						onMouseLeave={() => cursorChangeHandler('')}
						src={logo}
						alt="logo"
						className="w-[40px] h-[40px] md:w-[56px] md:h-[56px] xl:w-[70px] xl:h-[70px]"
					/>
				</Link>
				<div className="flex">
					<div className="flex mt-1 gap-2 md:gap-4 mr-6 md:mr-12 items-center text-base md:text-xl">
						<span
							className={` ${
								language === 'en' ? 'opacity-100' : 'opacity-50'
							}`}
							onClick={() => {
								changeLanguage('en');
							}}
							onMouseEnter={() => cursorChangeHandler('large')}
							onMouseLeave={() => cursorChangeHandler('')}
						>
							EN
						</span>
						{/* <div className="bg-white w-0.5 h-8" /> */}
						<span
							className={` ${
								language === 'fr' ? 'opacity-100' : 'opacity-50'
							}`}
							onClick={() => {
								changeLanguage('fr');
							}}
							onMouseEnter={() => cursorChangeHandler('large')}
							onMouseLeave={() => cursorChangeHandler('')}
						>
							FR
						</span>
					</div>
					<div
						className="menu-btn"
						ref={menuRef}
						onClick={toggleMenu}
						onMouseEnter={() => cursorChangeHandler('large')}
						onMouseLeave={() => cursorChangeHandler('')}
					>
						<div className="menu-btn__burger"></div>
					</div>
				</div>
			</motion.div>
			<AnimatePresence>
				{isOpen && (
					<motion.div
						className="fixed w-screen h-screen bg-[#1a1c20] left-0 top-0 z-[500]"
						initial={{ height: 0 }}
						animate={{ height: '100vh' }}
						transition={{ duration: 1, ease: 'easeInOut' }}
						exit={{
							height: 0,
							transition: {
								duration: 1,
								ease: 'easeInOut',
								delay: 1,
							},
						}}
					>
						<div className="h-screen flex justify-center items-center">
							<div className="main-container flex flex-col xl:flex-row justify-center items-start mx-auto gap-12 xl:gap-64">
								<div className="flex flex-col gap-4 xl:gap-6">
									<motion.h2
										className="text-4xl xl:text-6xl"
										initial={{ opacity: 0, x: -30 }}
										animate={{ opacity: 1, x: 0 }}
										transition={{
											duration: 1,
											delay: 0.5,
										}}
										exit={{
											opacity: 0,
											x: -30,
											transition: {
												duration: 0.5,
												delay: 0,
											},
										}}
									>
										Contacts
										<span className="text-primary font-black">
											.
										</span>
									</motion.h2>
									<div className="flex flex-col gap-2 xl:gap-4 text-xl xl:text-2xl font-light tracking-wide">
										<motion.a
											href="mailto:contact@elfadiliachraf.tech"
											onMouseEnter={() =>
												cursorChangeHandler('large')
											}
											onMouseLeave={() =>
												cursorChangeHandler('')
											}
											initial={{
												opacity: 0,
												x: -30,
											}}
											animate={{
												opacity: 1,
												x: 0,
											}}
											transition={{
												duration: 1,
												delay: 1,
											}}
											exit={{
												opacity: 0,
												x: -30,
												transition: {
													duration: 0.5,
												},
											}}
										>
											contact@elfadiliachraf.tech
										</motion.a>
										<motion.a
											href="tel:+212637799684"
											onMouseEnter={() =>
												cursorChangeHandler('large')
											}
											onMouseLeave={() =>
												cursorChangeHandler('')
											}
											initial={{
												opacity: 0,
												x: -30,
											}}
											animate={{
												opacity: 1,
												x: 0,
											}}
											transition={{
												duration: 1,
												delay: 1.5,
											}}
											exit={{
												opacity: 0,
												x: -30,
												transition: {
													duration: 0.5,
												},
											}}
										>
											+212 637 799 684
										</motion.a>
										<div className="flex gap-4">
											{socialMedia.map((link, index) => (
												<motion.a
													key={index}
													href={link.href}
													target="_blank"
													rel="noreferrer"
													onMouseEnter={() =>
														cursorChangeHandler(
															'hovered',
														)
													}
													onMouseLeave={() =>
														cursorChangeHandler('')
													}
													initial={{
														opacity: 0,
													}}
													animate={{
														opacity: 1,
													}}
													transition={{
														duration: 1,
														delay: index * 0.2 + 2,
													}}
													exit={{
														opacity: 0,
														transition: {
															duration: 0.5,
														},
													}}
												>
													{link.icon}
												</motion.a>
											))}
										</div>
									</div>
								</div>
								<div className="flex flex-col items-start justify-center gap-6 nav-links">
									{links.map((link, index) => (
										<span
											className="relative link-item ease-in-out duration-300"
											key={link.id}
										>
											<span className="absolute text-primary font-bold text-4xl xl:text-8xl link-number">
												{index + 1}
											</span>
											<motion.span
												onClick={() =>
													handleLinkClick(
														link.id,
														link.type,
													)
												}
												onMouseEnter={() =>
													cursorChangeHandler('large')
												}
												onMouseLeave={() =>
													cursorChangeHandler('')
												}
												className="font-head font-bold text-6xl xl:text-8xl tracking-wider"
												initial={{
													opacity: 0,
													y: -30,
												}}
												animate={{
													opacity: 1,
													y: 0,
												}}
												transition={{
													duration: 1,
													delay:
														index === 0
															? 1
															: index * 0.4 + 1,
												}}
												exit={{
													opacity: 0,
													y: -30,
													transition: {
														duration: 1,
														delay:
															index === 0
																? 0
																: index * 0.2,
													},
												}}
											>
												{
													content.navbar[language][
														link.key
													]
												}
											</motion.span>
										</span>
									))}
								</div>
							</div>
						</div>
					</motion.div>
				)}
			</AnimatePresence>
		</>
	);
}
