import { useContext } from 'react';

import { MouseContext } from '../../contexts/mouseContext';
import useMouse from '../../hooks/useMouse';
import seeMore from '../../assets/svgs/see-more.svg';
import voirPlus from '../../assets/svgs/voir-plus.svg';
import { LanguageContext } from '../../contexts/languageContext';

export default function Cursor() {
	const { x, y } = useMouse();
	const { cursorType } = useContext(MouseContext);
	const { language } = useContext(LanguageContext);

	return (
		<>
			<div
				style={{
					left: `${x}px`,
					top: `${y}px`,
				}}
				className={`cursor invisible lg:visible
					${cursorType ? cursorType : 'outer-cursor'} ${
					cursorType === 'large' ? ' w-[20px] h-[20px]' : ''
				}}`}
			>
				{cursorType === 'see-more' && (
					<img
						className="spin"
						src={language === 'en' ? seeMore : voirPlus}
						alt="see more"
					/>
				)}
			</div>
			<div
				style={{ left: `${x}px`, top: `${y}px` }}
				className={`cursor invisible lg:visible inner-cursor ${
					cursorType ? 'large' : ''
				}`}
			></div>
		</>
	);
}
