import { motion, useAnimationControls, useInView } from 'framer-motion';
import { useContext, useLayoutEffect, useRef } from 'react';
import Lottie from 'lottie-react';
import { ReactSVG } from 'react-svg';
import ImageDistort from 'react-image-list-distort';

import Button from '../macros/button';

import animationData from '../../assets/animations/globe.json';
import based from '../../assets/svgs/based.svg';
import base from '../../assets/svgs/base.svg';
import { content } from '../../global/language';
import { LanguageContext } from '../../contexts/languageContext';

export default function AboutSection() {
	const section = useRef();
	const isInView = useInView(section, { threshold: 1 });
	const imgControls = useAnimationControls();
	const textControls = useAnimationControls();
	const { language } = useContext(LanguageContext);

	useLayoutEffect(() => {
		if (isInView) {
			imgControls.start('visible');
			textControls.start('visible');
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isInView]);

	const textVariants = {
		hidden: { opacity: 0, x: -100 },
		visible: { opacity: 1, x: 0 },
	};

	const imageVariants = {
		hidden: { opacity: 0, height: 0 },
		visible: { opacity: 1, height: 'auto' },
	};

	const basicAnimation = {
		hidden: { opacity: 0, x: -100 },
		visible: { opacity: 1, x: 0 },
	};

	const fadeAnimation = {
		hidden: { opacity: 0 },
		visible: { opacity: 1 },
	};

	return (
		<div className="main-container" ref={section}>
			<div className="flex flex-col lg:flex-row gap-8 items-center w-full">
				<div className="flex w-full lg:w-1/2 flex-col gap-6 tracking-wider items-start justify-center z-10 lg:gap-16">
					<div className="flex flex-col gap-8">
						<motion.h1
							className="invisible text-2xl lg:visible"
							transition={{ duration: 2 }}
							variants={textVariants}
							initial="hidden"
							animate={textControls}
						>
							<span className="text-primary font-black">1.</span>{' '}
							<span className="font-normal">
								{content.about[language].title}
							</span>
						</motion.h1>
						<motion.h3
							className="relative md:font-bold tracking-widest font-head lg:whitespace-nowrap text-4xl md:text-4xl lg:text-7xl -mt-8"
							variants={textVariants}
							initial="hidden"
							animate={textControls}
							transition={{ duration: 2 }}
						>
							{content.about[language].heading}
						</motion.h3>
					</div>
					<motion.div
						variants={basicAnimation}
						initial="hidden"
						animate={textControls}
						transition={{ duration: 2, delay: 1 }}
						className="opacity-60 text-[1rem] font-light listRoot w-full md:text-[1.2rem] lg:text-[1.4rem] leading-4 md:leading-6 xl:leading-8"
					>
						{content.about[language].paragraph.part1}{' '}
						<span className="listItem special-word">
							Agadir
							<img
								src="https://ucarecdn.com/38e313a9-408c-4a3b-a521-3bc97d6a5785/-/scale_crop/256x256/center/"
								alt="Agadir"
								style={{ display: 'none' }}
							/>
						</span>
						{content.about[language].paragraph.part2}{' '}
						<span className="special-word listItem">
							1337
							<img
								src="https://ucarecdn.com/aa8d011d-d154-4188-9ec6-48cc5dc85494/-/scale_crop/256x256/center/"
								alt="1337 School"
								style={{ display: 'none' }}
							/>
						</span>
						{', '}
						{content.about[language].paragraph.part3}{' '}
						<span className="special-word listItem">
							Ben Guerir
							<img
								src="https://ucarecdn.com/a0efdff3-48e6-4b3f-873c-6a1162cba782/-/scale_crop/256x256/center/"
								alt="Ben Guerir"
								style={{ display: 'none' }}
							/>
						</span>
						{'. '}
						{content.about[language].paragraph.part4}
						<ImageDistort
							styles={{
								zIndex: 50,
							}}
							listRoot={'.listRoot'}
							itemRoot={'.listItem'}
							options={{
								strength: 1,
								effect: 'redshift',
								geometry: {
									shape: 'plane',
									segments: 10,
								},
							}}
						></ImageDistort>
					</motion.div>
					<motion.div
						variants={basicAnimation}
						initial="hidden"
						animate={textControls}
						transition={{ duration: 2, delay: 1.5 }}
					>
						<Button
							to={'/resume.pdf'}
							label={content.about[language].cta}
							download={'Achraf El Fadili Resume'}
						/>
					</motion.div>
				</div>
				<div className="relative w-full lg:w-1/2 ">
					<motion.div
						className="overflow-hidden"
						variants={imageVariants}
						initial="hidden"
						animate={imgControls}
						transition={{ duration: 2 }}
					>
						<picture>
							<source
								srcSet="https://ucarecdn.com/ddbea672-a417-46bc-9023-2ce5605e9427/-/crop/1172x843/328,0/-/format/webp/"
								media="(min-width: 1080px)"
								type="image/webp"
							/>
							<source
								srcSet="https://ucarecdn.com/ddbea672-a417-46bc-9023-2ce5605e9427/-/scale_crop/500x340/smart/-/format/webp/"
								media="(min-width: 340px)"
								type="image/webp"
							/>
							<img
								src="https://ucarecdn.com/ddbea672-a417-46bc-9023-2ce5605e9427/-/crop/1172x843/328,0/-/format/webp/"
								alt="Achraf El fadili"
								className="w-full object-cover h-full"
							/>
						</picture>
					</motion.div>
					<div className="absolute right-0 bottom-0 lg:translate-x-1/2 md:translate-y-1/2 translate-y-1/4">
						<motion.div
							variants={{
								hidden: { opacity: 0, scale: 0 },
								visible: { opacity: 1, scale: 1 },
							}}
							initial="hidden"
							animate={imgControls}
							transition={{ duration: 2 }}
							className="w-[90px] h-[90px] p-4 relative bg-glass rounded-full md:w-[140px] md:h-[140px] lg:w-[200px] lg:h-[200px] lg:p-7"
						>
							<motion.span
								className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2"
								variants={fadeAnimation}
								initial="hidden"
								animate={imgControls}
								transition={{ duration: 2, delay: 2 }}
							>
								<ReactSVG
									src={
										language === 'en'
											? based
											: language === 'fr'
											? base
											: ''
									}
									className="w-[80px] h-[80px] spin md:w-[120px] md:h-[120px] lg:w-[170px] lg:h-[170px]"
								/>
							</motion.span>
							<Lottie
								autoplay
								loop
								animationData={animationData}
								className="rotate-12"
								rendererSettings={{
									preserveAspectRatio: 'xMidYMid slice',
								}}
							/>
						</motion.div>
					</div>
				</div>
			</div>
		</div>
	);
}
