import React from 'react';
import ReactDOM from 'react-dom/client';
import { HashRouter } from 'react-router-dom';
import AnimatedRoute from './components/animatedRoute';
import Cursor from './components/macros/cursor';
import MouseContextProvider from './contexts/mouseContext';
import LanguageContextProvider from './contexts/languageContext';

import './styles/index.css';

function App() {
	return (
		<HashRouter hashType="noslash">
			<LanguageContextProvider>
				<MouseContextProvider>
					<Cursor />
					<AnimatedRoute />
				</MouseContextProvider>
			</LanguageContextProvider>
		</HashRouter>
	);
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);
