import { motion } from 'framer-motion';
import React, { useEffect } from 'react';

import Navbar from '../components/navbar';
import Button from '../components/macros/button';
import { LanguageContext } from '../contexts/languageContext';

function Gallery() {
	const { language } = React.useContext(LanguageContext);
	useEffect(() => {
		window.document.title = `Achraf El fadili | ${
			language === 'en' ? 'Gallery' : 'Galerie'
		}`;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [language]);

	return (
		<motion.main
			initial={{ opacity: 0 }}
			animate={{ opacity: 1 }}
			transition={{ duration: 1 }}
			exit={{ opacity: 0, y: '-100%' }}
			className="relative w-screen h-screen text-white flex items-center justify-center"
		>
			<Navbar />
			<div className="flex flex-col gap-16 items-center">
				<div className="flex flex-col items-center">
					<span className="text-[4rem] lg:text-[8rem] tracking-wider font-head font-bold text-center leading-[100px]">
						{language === 'en' ? 'under' : 'sous'} <br />
						construction
					</span>
				</div>
				<Button
					label={
						language === 'en'
							? 'Back to home'
							: "Retour à l'acceuil"
					}
					to="/"
					type="internal"
				/>
			</div>
		</motion.main>
	);
}

export default Gallery;
