import React, { useEffect } from 'react';
import { motion, useAnimationControls } from 'framer-motion';

const Wrapper = (props) => {
	return <span>{props.children}</span>;
};

export default function TextAnimation({ text, isLoaded }) {
	const animationController = useAnimationControls();

	useEffect(() => {
		if (isLoaded) {
			setTimeout(() => {
				animationController.start('visible');
			}, 1000);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isLoaded]);

	const splitWords = text.split(' ');
	const words = [];

	splitWords.map((word) => {
		return words.push(word.split(''));
	});

	words.map((word, index) => {
		if (index !== words.length - 1) {
			return word.push('\u00A0');
		}
		return word;
	});

	return (
		<>
			{words.map((word, index) => {
				return (
					<Wrapper key={index}>
						{words[index].flat().map((element, index) => {
							return (
								<span
									className="overflow-hidden inline-block xl:pb-9 xl:pl-1 xs:pb-3 xs:pl-[2px] md:pb-6"
									key={index}
								>
									<motion.span
										style={{ display: 'inline-block' }}
										initial="hidden"
										animate={animationController}
										variants={{
											hidden: {
												y: '200%',
											},
											visible: {
												y: 0,
												transition: {
													duration: 0.8,
													delay: index * 0.1,
													ease: [
														0.455, 0.03, 0.515,
														0.955,
													],
												},
											},
										}}
									>
										{element}
									</motion.span>
								</span>
							);
						})}
					</Wrapper>
				);
			})}
		</>
	);
}
