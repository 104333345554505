import React, { useContext, useEffect, useRef, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import emailjs from '@emailjs/browser';

import Button from '../macros/button';
import { MouseContext } from '../../contexts/mouseContext';
import { LanguageContext } from '../../contexts/languageContext';

import discord from '../../assets/svgs/discord.svg';
import github from '../../assets/svgs/github.svg';
import dribbble from '../../assets/svgs/dribbble.svg';
import linkedin from '../../assets/svgs/linkedin.svg';
import { content } from '../../global/language';

const socialMedia = [
	{
		href: 'https://github.com/0xShady',
		label: 'Github',
		icon: <img src={github} alt="github" />,
	},
	{
		href: 'https://www.linkedin.com/in/achraf-el-fadili/',
		label: 'Linkedin',
		icon: <img src={linkedin} alt="linkedin" />,
	},
	{
		href: 'https://discordapp.com/users/603373384490942482',
		label: 'Discord',
		icon: <img src={discord} alt="discord" />,
	},
	{
		href: 'https://dribbble.com/achraf_elfadili',
		label: 'Dribble',
		icon: <img src={dribbble} alt="dribbble" />,
	},
];

export default function ContactSection() {
	const { cursorChangeHandler } = useContext(MouseContext);
	const { language } = useContext(LanguageContext);
	const [formError, setFormError] = useState({
		name: '',
		email: '',
		subject: '',
		message: '',
	});
	const { ref, inView } = useInView({
		triggerOnce: true,
		threshold: 0.5,
	});
	const [isInView, setIsInView] = useState(false);
	const form = useRef(null);

	useEffect(() => {
		if (inView) {
			setIsInView(true);
		}
	}, [inView]);

	const inputsVariants = {
		hidden: {
			opacity: 0,
			y: -20,
		},
		visible: {
			opacity: 1,
			y: 0,
		},
	};

	const sendEmail = (e) => {
		e.preventDefault();
		if (
			!form.current.email.value ||
			!/\S+@\S+\.\S+/.test(form.current.email.value)
		) {
			setFormError({ ...formError, email: 'Please enter a valid email' });
			return;
		}
		if (!form.current.name.value) {
			setFormError({ ...formError, name: 'Please enter your name' });
			return;
		}
		if (!form.current.subject.value) {
			setFormError({ ...formError, subject: 'Please enter a subject' });
			return;
		}
		if (!form.current.message.value) {
			setFormError({ ...formError, message: 'Please enter a message' });
			return;
		}
		emailjs
			.sendForm(
				'service_aiz8pa1',
				'template_p8l4qmn',
				form.current,
				'1QJcFfXwM86cWpg9w',
			)
			.then((result) => {
				form.current.reset();
				setFormError({
					name: '',
					email: '',
					subject: '',
					message: '',
				});
			});
	};

	return (
		<div
			className="flex items-center justify-center w-full h-screen"
			ref={ref}
		>
			<AnimatePresence>
				{isInView && (
					<div className="flex main-container flex-col md:flex-row">
						<div className="flex flex-col w-full md:w-1/2 gap-6 md:gap-16 md:mt-8">
							<motion.div
								variants={{
									hidden: {
										opacity: 0,
										x: -120,
									},
									visible: {
										opacity: 1,
										x: 0,
									},
								}}
								transition={{
									duration: 1,
									delay: 1,
								}}
								initial="hidden"
								animate="visible"
								className="flex flex-col"
							>
								<h3 className="invisible text-2xl md:visible">
									<span className="text-primary font-black">
										3.
									</span>{' '}
									{content.contact[language].title}
								</h3>
								<h1 className="text-5xl md:text-8xl font-head tracking-wider">
									{content.contact[language].heading}
								</h1>
							</motion.div>
							<div className="flex flex-col gap-4">
								<motion.div
									variants={{
										hidden: {
											opacity: 0,
											x: -120,
										},
										visible: {
											opacity: 1,
											x: 0,
										},
									}}
									transition={{
										duration: 1,
										delay: 1,
									}}
									initial="hidden"
									animate="visible"
									className="w-[100px] md:w-[200px] bg-white h-[2px]"
								></motion.div>
								<ul className="flex gap-4 nav-links">
									{socialMedia.map((link, index) => (
										<li key={index}>
											<motion.a
												href={link.href}
												target="_blank"
												rel="noreferrer"
												onMouseEnter={() =>
													cursorChangeHandler(
														'hovered',
													)
												}
												onMouseLeave={() =>
													cursorChangeHandler('')
												}
												initial={{
													opacity: 0,
												}}
												animate={{
													opacity: 1,
												}}
												transition={{
													duration: 1,
													delay: index * 0.2 + 2,
												}}
												exit={{
													opacity: 0,
													transition: {
														duration: 0.5,
													},
												}}
											>
												{link.icon}
											</motion.a>
										</li>
									))}
								</ul>
							</div>
						</div>
						<div className="w-full md:w-1/2 flex flex-col gap-8 md:gap-10">
							<form
								onSubmit={sendEmail}
								ref={form}
								className="flex flex-col gap-3 md:gap-6 w-full"
							>
								<motion.div
									variants={inputsVariants}
									transition={{
										duration: 1,
										delay: 1,
									}}
									initial="hidden"
									animate="visible"
									className="form__group"
								>
									<input
										className="form__field"
										placeholder="Full Name"
										name="name"
										type="text"
									/>
									<label className="form__label text-xl">
										{content.contact[language].form.name}
										{formError.name && (
											<span className="text-[#b94141] text-sm">
												{formError.name}
											</span>
										)}
									</label>
								</motion.div>
								<motion.div
									variants={inputsVariants}
									transition={{
										duration: 1,
										delay: 1.5,
									}}
									initial="hidden"
									animate="visible"
									className="form__group"
								>
									<input
										className="form__field"
										placeholder="Email"
										name="email"
										type="email"
									/>
									<label className="form__label text-xl">
										{content.contact[language].form.email}
										{formError.email && (
											<span className="text-[#b94141] text-sm">
												{formError.email}
											</span>
										)}
									</label>
								</motion.div>
								<motion.div
									variants={inputsVariants}
									transition={{
										duration: 1,
										delay: 2,
									}}
									initial="hidden"
									animate="visible"
									className="form__group"
								>
									<input
										className="form__field"
										placeholder="Subject"
										name="subject"
										type="text"
									/>
									<label
										className="form__label text-xl"
										htmlFor="subject"
									>
										{content.contact[language].form.subject}
										{formError.subject && (
											<span className="text-[#b94141] text-sm">
												{formError.subject}
											</span>
										)}
									</label>
								</motion.div>
								<motion.div
									variants={inputsVariants}
									transition={{
										duration: 1,
										delay: 2.5,
									}}
									initial="hidden"
									animate="visible"
									className="form__group"
								>
									<textarea
										className="form__field h-16 md:h-28"
										name="message"
										placeholder="message"
									/>
									<label
										className="form__label text-xl"
										htmlFor="message"
									>
										{content.contact[language].form.message}
										{formError.message && (
											<span className="text-[#b94141] text-sm">
												{formError.message}
											</span>
										)}
									</label>
								</motion.div>
								<motion.div
									variants={inputsVariants}
									transition={{
										duration: 1,
										delay: 3,
									}}
									initial="hidden"
									animate="visible"
									className="pt-4 md:pt-8"
								>
									<Button
										label={
											content.contact[language].form
												.button
										}
										submit={sendEmail}
									/>
								</motion.div>
							</form>
						</div>
					</div>
				)}
			</AnimatePresence>
		</div>
	);
}
