import React, {
	useLayoutEffect,
	useEffect,
	useRef,
	useContext,
	useState,
} from 'react';
import { motion, useAnimationControls, useInView } from 'framer-motion';
import { useNavigate } from 'react-router-dom';

import { LanguageContext } from '../../contexts/languageContext';
import { MouseContext } from '../../contexts/mouseContext';
import { content } from '../../global/language';
import useWindowSize from '../../hooks/useWindowSize';

const ProjectCard = ({ title, thumb, index, to }) => {
	const { cursorChangeHandler } = useContext(MouseContext);
	const navigate = useNavigate();

	return (
		<div
			className="relative h-[380px] w-[300px] md:w-[460px] lg:w-[600px] lg:h-[500px] project-card text-[2.5rem] md:text-[4rem] font-head tracking-wider"
			onMouseEnter={() => {
				cursorChangeHandler('see-more');
			}}
			onMouseLeave={() => {
				cursorChangeHandler('');
			}}
			onClick={() => {
				cursorChangeHandler('');
				navigate(to);
			}}
		>
			<div className="z-[100] absolute image-container h-full transition-transform">
				<picture>
					<source
						srcSet={`${thumb}-/format/webp/`}
						media="(min-width: 720px)"
						type="image/webp"
					/>
					<source
						srcSet={`${thumb}-/scale_crop/300x380/smart/-/format/webp/`}
						type="image/webp"
					/>
					<img
						className="w-full h-full object-cover transition-transform grayscale brightness-75"
						src={thumb}
						alt={title}
					/>
				</picture>
			</div>
			<h4
				data-text={title}
				className={
					'absolute -left-4 font-head md:-left-16 transition-all text-shadow' +
					(index % 2 === 0 ? ' bottom-4 ' : ' top-4 ')
				}
			>
				{title}
			</h4>
			<h4
				data-text={title}
				className={
					'outline-text -left-4 z-[100] absolute font-head md:-left-16 transition-all' +
					(index % 2 === 0 ? ' bottom-4 ' : ' top-4 ')
				}
			>
				{title}
			</h4>
		</div>
	);
};

export default function ProjectsSection() {
	const windowSize = useWindowSize();
	const scrollContainer = useRef();
	const skewContainer = useRef();
	const parentContainer = useRef();
	const lastElement = useRef();
	const firstElement = useRef();
	const firstElementInView = useInView(firstElement, { threshold: 1 });
	const lastElementInView = useInView(lastElement, { threshold: 1 });
	const isInView = useInView(scrollContainer, { threshold: 1 });
	const textControls = useAnimationControls();
	const ProjectControls = useAnimationControls();
	const firstElementInViewState = useRef(firstElementInView);
	const lastElementInViewState = useRef(lastElementInView);
	const [Projects, setProjects] = useState([]);
	const { language } = useContext(LanguageContext);

	const scrollSkewConfig = {
		ease: 0.05,
		current: 0,
		previous: 0,
		rounded: 0,
	};

	useEffect(() => {
		scrollContainer.current.scrollLeft = 0;
		skewScrolling();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		parentContainer?.current.addEventListener('wheel', handleScroll, {
			passive: false,
		});
		// return () => {
		// 	parentContainer?.current.removeEventListener('wheel', handleScroll);
		// };
	}, []);

	useLayoutEffect(() => {
		firstElementInViewState.current = firstElementInView;
	}, [firstElementInView]);

	useLayoutEffect(() => {
		lastElementInViewState.current = lastElementInView;
	}, [lastElementInView]);

	const handleScroll = (event) => {
		if (firstElementInViewState.current && event.deltaY < 0) {
			return;
		}
		if (lastElementInViewState.current && event.deltaY > 0) {
			return;
		}
		event.preventDefault();
		event.stopPropagation();
		scrollContainer.current.scrollLeft += event.deltaY * 0.5;
	};

	const skewScrolling = () => {
		if (!scrollContainer.current) return;
		scrollSkewConfig.current = scrollContainer.current.scrollLeft;
		scrollSkewConfig.previous +=
			(scrollSkewConfig.current - scrollSkewConfig.previous) *
			scrollSkewConfig.ease;
		scrollSkewConfig.rounded =
			Math.round(scrollSkewConfig.previous * 100) / 100;

		const difference = scrollSkewConfig.current - scrollSkewConfig.rounded;
		const acceleration = difference / windowSize.width;
		const velocity = +acceleration;
		const skew = velocity * 20;

		skewContainer.current.style.transform = `translate3d(-${scrollSkewConfig.rounded}px,0 ,0) skew(${skew}deg)`;

		requestAnimationFrame(() => skewScrolling());
	};

	useLayoutEffect(() => {
		console.log(isInView);
		if (isInView) {
			textControls.start('visible');
			ProjectControls.start('visible');
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isInView]);

	const fetchProject = () => {
		fetch('https://api.jsonbin.io/v3/b/66976960ad19ca34f888cb38', {
			method: 'GET',
			headers: {
				'X-Master-Key':
					'$2b$10$dITfFymcGcrfo.2KKjmyMeMq2FJWMBqn5Q0pKeaGPFOlJ0JdoLRle',
			},
		})
			.then((res) => res.json())
			.then((data) => {
				setProjects(data.record);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	useEffect(() => {
		fetchProject();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const textVariants = {
		hidden: { opacity: 0, x: -100 },
		visible: { opacity: 1, x: 0 },
	};

	const projectVariants = {
		hidden: { opacity: 0, y: 400 },
		visible: { opacity: 1, y: 0 },
	};

	return (
		<div
			className="flex items-center flex-col gap-8 justify-center h-full w-full"
			ref={parentContainer}
		>
			<div className="flex flex-col items-start justify-center main-container mt-12">
				<motion.h1
					className="invisible text-2xl lg:visible"
					transition={{ duration: 2 }}
					variants={textVariants}
					initial="hidden"
					animate={textControls}
				>
					<span className="text-primary font-black">2.</span>{' '}
					<span className="font-normal">
						{content.projects[language].title}
					</span>
				</motion.h1>
				<motion.h3
					className="text-3xl md:text-5xl xl:text-6xl tracking-wider font-light lg:font-bold font-head"
					variants={textVariants}
					initial="hidden"
					animate={textControls}
					transition={{ duration: 2 }}
				>
					{content.projects[language].heading}
				</motion.h3>
			</div>
			<div
				className="relative w-full h-[400px] md:h-[510px] overflow-x-auto scroll-bar-hide"
				ref={scrollContainer}
			>
				<div className="absolute flex" ref={skewContainer}>
					<div
						style={{ width: `${windowSize.width / 10}px` }}
						ref={firstElement}
					></div>
					<div className="flex gap-8 lg:gap-[8rem]">
						{Projects.map((project, index) => (
							<motion.div
								key={index}
								variants={projectVariants}
								initial="hidden"
								animate={ProjectControls}
								transition={{
									duration: 1.4,
									delay: index * 0.8,
								}}
							>
								<ProjectCard
									title={project.title}
									thumb={project.thumb}
									index={index}
									to={project.to}
								/>
							</motion.div>
						))}
					</div>
					<div
						style={{ width: `${windowSize.width / 10}px` }}
						ref={lastElement}
					></div>
				</div>
			</div>
		</div>
	);
}
